.app-layout {
  width: 100%;
  min-height: 100vh;
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
  }

  &.app-layout_manager {
    .app-layout-wrapper {
    }
  }

  &.app-layout_admin {
    .app-layout-wrapper {
      background: #0e2b00;
    }
  }

  &.app-layout_customer {
    .app-layout-wrapper {
      background: #000000;
    }
  }
}

.app-layout-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  background: #09150b;
  padding: 5px;

  @media (max-width: 450px) {
    flex-direction: column;
    min-height: 100%;
    flex: 1;
  }
}

.app-layout__sidebar {
  flex-basis: 260px;
  position: relative;

  @media (max-width: 450px) {
    position: absolute;
    background: #09150b;
    z-index: 100;
    width: 100%;
    height: 100%;
    min-height: 100%;
    flex: 1;
    left: 0;
    top: 0;
  }
}

.app-layout__content {
  flex: 1;
  background: #fff;
  padding: 50px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 450px) {
  .app-layout__content {
    padding: 8px;
  }
}



@media (max-width: 450px) {
  .app-layout__sidebar-burger {
    display: block;
    right: 10px;
    top: 10px;
    z-index: 1000;
    margin-left: 18px;
  }
}

@media (min-width: 450px) {

  .app-layout__sidebar-burger {
    display: none;
  }
}
